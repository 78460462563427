import * as React from "react"
import SEO from "../components/SEO/SEO";
import { Layout } from "../components/layout/layout";
import { Quote } from "../components/quote/quote";
import { Arrow } from "../components/arrow/arrow";
import locationIcon from "../images/location-dark.svg";
import "./contact.scss";
import ContactForm from "../components/contact-form/contact-form";

const ContactPage = () => {

  return (
    <main className="my-contact">
      <SEO
        title="Contact Us"
        pathname="/contact"
      />
      <Layout page="contact">
        <Quote
          text="We are your"
          highlightedText="one stop solution"
          additionalText="to all your yachting needs."
        />
        <Arrow alignBottom={false} url="contact-form" />
        <div id="contact-form" className="my-contact-header">
          <h2 className="my-contact__title">FIND OUT HOW OUR TEAM CAN ASSIST YOU</h2>
          <p className="my-contact__subtitle">Please complete the form below to Email us and our team will respond to your inquiry shortly.</p>
          <div className="my-contact__location">
            <img src={locationIcon} alt="location" />
            <div className="my-contact__location-detail">
              <span>2550 S Bayshore Dr. </span>
              <span>Coconut Grove, FL 33133</span>
            </div>
          </div>
        </div>
        <div className="my-contact-content">
          <iframe
            className="my-contact__map"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=2550%20S%20Bayshore%20Dr.%20Coconut%20Grove,%20FL%2033133&t=&z=13&ie=UTF8&iwloc=&output=embed"
            title="2550 S Bayshore Dr. Coconut Grove, FL 33133"
            aria-label="2550 S Bayshore Dr. Coconut Grove, FL 33133"
          />
          <div className="my-contact-fields">
            <ContactForm />
          </div>
        </div>
      </Layout>
    </main>
  )
}

export default ContactPage;
